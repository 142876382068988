<template>
  <div class="login-container">
    <div class="login-section">
      <img src="@/assets/logo.jpg" alt="Logo" class="logo">
      <h2>INICIA SESIÓN</h2>
      <form @submit.prevent="login">
        <div class="input-container">
          <input type="text" id="username" v-model="credentials.username" placeholder="Usuario" required>
        </div>
        <div class="input-container">
          <input type="password" id="password" v-model="credentials.password" placeholder="Contraseña" required>
        </div>
        <button type="submit">Iniciar Sesión</button>
      </form>
    </div>
    <div class="info-section">
      <h1 class="platform-title">PLATAFORMA DE GESTIÓN ECONÓMICA</h1>
    </div>
  </div>
</template>

<style>
@import '@/assets/styles/LoginView.css';
</style>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    login() {
      // Aquí iría la lógica de inicio de sesión
      alert(`Nombre de Usuario: ${this.credentials.username}, Contraseña: ${this.credentials.password}`);
    },
  },
};
</script>