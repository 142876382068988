<template>
  <div id="app">
    <NavBar v-if="navbarState.showNavbar" />

    <router-view/>
  </div>
</template>

<script>
import { navbarState } from './store/navbarState'; // Ajusta la ruta de importación según corresponda
import NavBar from './components/NavBar.vue'; // Ajusta esto según corresponda

export default {
  components: {
    NavBar
  },
  setup() {
    return { navbarState };
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
