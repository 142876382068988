<!-- src/components/NavBar.vue -->
<template>
    <nav>
      <!-- Tu código de NavBar aquí. Ejemplo: -->
      <a href="/">Inicio</a>
      <a href="/about">Acerca de</a>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavBar',
    // cualquier lógica específica de tu NavBar aquí
  }
  </script>
  
  <style scoped>
  /* Estilos específicos de NavBar aquí */
  nav {
    padding: 30px;
  }
  a {
    font-weight: bold;
    color: #2c3e50;
  }
  a.router-link-exact-active {
    color: #42b983;
  }
  </style>
  